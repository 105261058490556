import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useEffect } from "react"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : { className: "nav-link" }
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const Footer = ({ siteTitle }) => (
  <>
    {/* <!-- Copyright Section--> */}
    <div className="copyright py-4 text-center text-white">
      <div className="container"><small>Copyright &copy; Just Hire a Handyman 2021</small><br></br><strong><small>ABN 31 242 580 816</small></strong></div>
    </div>
  </>
)


Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
