import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useEffect } from "react"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : { className: "nav-link" }
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const Navbar = ({ siteTitle }) => (
  <nav className="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
    <div className="container">
      <ExactNavLink to={'/#'} className="navbar-brand" href="#page-top">{siteTitle}</ExactNavLink>
      <button className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
            <i className="fas fa-bars"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item mx-0 mx-lg-1"><ExactNavLink className="nav-link py-3 px-0 px-lg-3 rounded" to={"/#services"}>Our Services</ExactNavLink></li>
          <li className="nav-item mx-0 mx-lg-1"><ExactNavLink className="nav-link py-3 px-0 px-lg-3 rounded" to={"/#about"}>About</ExactNavLink></li>
          <li className="nav-item mx-0 mx-lg-1"><ExactNavLink className="nav-link py-3 px-0 px-lg-3 rounded" to={"/#contact"}>Contact Us</ExactNavLink></li>
        </ul>
      </div>
    </div>
  </nav>
)


Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
